
.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
  margin-left: 10px;
}

.square {
  background: #8f8;
  border: 0px solid #999;
  border-radius: 2px;
  float: left;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  height: 28px;
  margin: 1px;
  text-align: center;
  width: 28px;
  color: rgba(0,0,0, 0.6);
}
.square.isClicked {
  background: #fff;
}
.loser .square {
  background: #f00;
}
    


.square:focus {
  outline: none;
}

.todoActive:first-child {
    background: #f00;
}
.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.inputField {
  margin: 10px;
  margin-left: 0px;
}

button {
  cursor: pointer;
}

.grid-subtitle {
  font-size: 14px;
  overflow: visible;
  white-space: normal;
}

.MuiGridListTile-tile {
  background-color: #999;
}

.MuiListItem-container > .MuiListItemSecondaryAction-root {
  display: none;
}
.MuiListItem-container:hover > .MuiListItemSecondaryAction-root {
  display: block;
}

.MuiListItemText-root {
    margin-top: 37px;
}

.container{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
	grid-gap: 2em;
}

.bulma-card-content {
    height: 140px;
}

.MuiDrawer-paper {
  max-width: 75% !important;
}